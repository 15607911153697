import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', component: () => import('../views/HomeView.vue') },
  { path: "/about", component: () => import('../views/About.vue'),  meta: { title: "About Us" },},
  { path: "/contacts", component: () => import('../views/Contacts.vue'),  meta: { title: "Contact" },},
  { path: "/faq", component: () => import('../views/Faq.vue'),  meta: { title: "FAQ" },},
  { path: "/disclaimer", component: () => import('../views/Disclaimer.vue'),  meta: { title: "Disclaimer" },},
  { path: "/donations", component: () => import('../views/Donations.vue'),  meta: { title: "Donations" },},
  { path: "/privacy", component: () => import('../views/Privacy.vue'),  meta: { title: "Privacy Policy" },},
  { path: "/products", component: () => import('../views/Products.vue'),  meta: { title: "Products" },},
  { path: "/terms", component: () => import('../views/Terms.vue'),  meta: { title: "Terms of use" },},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
